<template>
    <v-container class="container--fluid grid-list-md">
        <v-progress-circular
                :width="10"
                :size="80"
                indeterminate
                v-if="getLoading"
        ></v-progress-circular>
        <div v-if="!getLoading">
            <div class="d-flex justify-space-between mb-3 ">
                <div class="d-flex" v-if="currentCat">
                    <h3>{{currentCat.name}}</h3>
                    <v-btn
                            small
                            dark
                            color="green"
                            class="ml-5"
                            @click="$router.push(`/product/${currentCat.id}/new`)"
                    >
                        <v-icon small class="mr-2">mdi-file-plus
                        </v-icon
                        >
                        {{ $t("btns.add_product") }}
                    </v-btn>
                </div>
                <v-btn
                        small
                        @click="changeViewExpand()"
                >
                    <v-icon small left>mdi-eye-outline</v-icon>
                    <span v-if="activeRowsEx.length === productsList.length">{{ $t('btns.hide_details') }}</span>
                    <span v-else>{{ $t('btns.show_details') }}</span>
                </v-btn>
            </div>
            <div
                    class="wrap-progress"
            >
                <v-progress-linear
                        indeterminate
                        height="2"
                        light
                        absolute
                        :active="getLoading"
                ></v-progress-linear>
            </div>
            <custom-table
                    :headers="headers"
                    :items="products"
                    selectable="multiple"
                    :expand-rows="expandRows"
                    @toggleExpand="toggleEx"
                    :active-expand-rows="activeRowsEx"
                    height="calc(100vh - 160px)"
                    @send="open"
                    @eSort="eSort"
            />
            <div class="text-center">
                <v-pagination
                        class="mt-2"
                        v-model="current_page"
                        :length="all_page"
                        :total-visible="7"
                ></v-pagination>
            </div>
        </div>
    </v-container>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "Products",
        data() {
            return {
                productsList: [],
                activeRowsEx: [],
                searchProduct: null,
                current_page: 1,
                currentCat: null,
                all_page: null,
                size_per_page: 30,
                headers: [
                    {
                        text: this.$t('fields.category'),
                        value: "categoryName",
                        width: 230,
                        sortable: true,
                        edit: false,
                        search: true,
                    },
                    {
                        text: this.$t('fields.Product_Code_SKU'),
                        value: "code",
                        width: 130,
                        sortable: true,
                        edit: false,
                        search: true,
                    },
                    {
                        text: this.$t('fields.name2'),
                        value: "name",
                        width: 500,
                        sortable: true,
                        edit: false,
                        search: true,
                        template: 'name',
                    },
                    {
                        text: this.$t('fields.vendor'),
                        value: "vendor",
                        width: 200,
                        sortable: true,
                        edit: false,
                        search: true,
                    },
                ],
                expandRows: [
                    {
                        value: "description",
                        template: "description",
                        width: 400,
                        title: this.$t('fields.description'),
                    },
                    {
                        value: "name",
                        template: "params",
                        width: 200,
                        title: this.$t('fields.parameters_seller'),
                    },
                    {
                        value: "name",
                        template: "images",
                        width: 500,
                        title: this.$t('fields.photo'),
                    },
                ],
                params: {},
            }
        },
        mounted() {
            this.params["category[id]"] = this.catId
            this.getApiProducts()
            this.currentCategory(this.catId).then(function () {
                let category = this.getCurrentCategory
                this.currentCat = {
                    ...category.translations[0],
                    ...category
                }
            }.bind(this))

        },
        computed: {
            ...mapGetters(["getProducts", "getLoading", "getCurrentCategory", "getCats"]),
            products() {
                let arr = []
                for (let product of this.productsList) {
                    let obj = {
                        ...product.translations[0],
                        ...product,
                    }
                    arr.push(obj)
                }
                return arr
            },
            catId() {
                return this.$route.params.id;
            },
        },
        watch: {
            current_page() {
                this.params.page = this.current_page
                this.getApiProducts()
            },
            catId() {
                this.params["category[id]"] = this.catId
                this.getApiProducts()
            }
        },
        methods: {
            ...mapActions(["initProducts", "currentCategory", "initCat"]),
            open(e) {
                if (e.id)
                    this.$router.push(`/product/${this.currentCat.id}/${e.id}`)
            },
            getApiProducts() {
                this.initProducts(this.params).then(function (success) {
                    let response = this.getProducts
                    this.productsList = response.items
                    this.all_page = response._meta.pageCount

                  // this.params["filter[parent]"] = this.catId
                  // this.initCat(this.params).then(function (success) {
                  //   console.log(this.productsList)
                  //   this.productsList.push(...this.getCats.items)
                  //   console.log(this.productsList)
                  // }.bind(this))

                }.bind(this))

            },
            eSort(sort) {
                this.params.sort = sort.dir === "desc" ? sort.by : "-" + sort.by
                // 'add': false,
                this.getApiProducts(this.params)
            },
            toggleEx(e) {
                this.activeRowsEx = e
            },
            changeViewExpand() {
                let arr = []
                if (this.activeRowsEx.length === this.products.length) {
                    this.activeRowsEx = []
                } else if (this.activeRowsEx.length < this.products.length) {
                    this.products.forEach(prod => arr.push(prod.id))
                    this.activeRowsEx = arr
                }
            },
        },
    }
</script>

<style scoped>

</style>
